/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { baseInput } from '../../elements/input/styles';
import { routes } from '../../shared/constants';
import RichText from '../../elements/richText';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding: 0px 40px;

  @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
    padding: 0px 30px;
  } 
`;

const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
`;

const StyledTextarea = styled.textarea`
  ${baseInput}
`;

const SelectWrapper = styled.div`
  position: relative; 

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    right: 20px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.colours.slateLight};
    width: 0px;
    height: 0px;
    transition: all 0.35s ease 0s;
  }
`;

const StyledSelect = styled.select`
  ${baseInput}
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const Captcha = styled.div`
  margin-bottom: 30px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    overflow-x: scroll;
    width: 112%;
    position: relative;
    left: -6%;
  }
`;

const SuccessContainer = styled.div`
  color: ${props => props.theme.colours.green};
  margin: 30px 0px;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.colours.red};
  margin: 30px 0px;
`;

const ContactDetails = styled.div`
  padding: 40px 0px;
  background: ${props => props.theme.colours[props.background.toLowerCase()]}; 
`;

const ContactDetailsRow = styled.div`
  margin-left: -15px;
  margin-right: -15px;

  @media(min-width: ${props => props.theme.breakpoints.min.md}){
    display: flex;
  }
`;

const ContactDetailsCol = styled.div`
  flex: 0 0 50%;
  padding: 0px 15px;
  box-sizing: border-box;

  a {
    word-break: break-all;
  }
`;

export default function ContactForm({
  data
}) {
  const {
    hashId,
    subjects,
    submitButton,
    errorMessage,
    successMessage,
    contactDetailsBackground,
    contactDetailsCol1,
    contactDetailsCol2
  } = data;

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaResponse = grecaptcha.getResponse();

    if (!!captchaResponse) {
      const formData = new FormData(document.getElementById(`contactform-${hashId}`));

      fetch(`${routes.global.urls.domains.root}mobile/submitcontact/?__amp_source_origin=https%3A%2F%2F${window.document.domain}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'amp-same-origin': 'true'
        },
        body: formData
      })
        .then(response => response.json())
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(() => {
          setError(true);
          setSuccess(false);
        });
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  useEffect(() => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.head.appendChild(recaptchaScript);
  }, []);

  return (
    <div id={hashId}>
      <StyledContainer>
        <form id={`contactform-${hashId}`} method="post" target="_top" onSubmit={handleSubmit}>
          <div className="form-fields">
            <FieldContainer>
              <StyledLabel htmlFor="firstname">First name*</StyledLabel>
              <StyledInput id="firstname" name="firstname" type="text" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="lastname">Last name*</StyledLabel>
              <StyledInput id="lastname" name="lastname" type="text" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="phone">Phone*</StyledLabel>
              <StyledInput id="phone" name="phone" type="tel" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="email">Email*</StyledLabel>
              <StyledInput id="email" name="email" type="email" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="preferredcontact">Preferred contact*</StyledLabel>
              <StyledInput id="preferredcontact" name="preferredcontact" type="text" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="regarding">I have a question regarding*</StyledLabel>
              <SelectWrapper>
                <StyledSelect id="regarding" name="regarding" required>
                  <option value=""> - select - </option>
                  {subjects.map((subject) => (
                    <option value={subject} key={subject}>{subject}</option>
                  ))}
                </StyledSelect>
              </SelectWrapper>
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="message">Message*</StyledLabel>
              <StyledTextarea id="message" name="message" rows="5" wrap="on" required />
            </FieldContainer>
          </div>

          {/* Captcha */}
          <Captcha>
            <div className="g-recaptcha" data-sitekey="6Lf4gAoUAAAAAPE0e38GYNMFXsHT7FCUHf3IW_Z0" />
          </Captcha>

          {/* Button */}
          <div className="buttonWrap">
            <input type="submit" className="button btn-green" value={submitButton} />
          </div>

          {/* Success */}
          {success && <SuccessContainer>{successMessage}</SuccessContainer>}

          {/* Error */}
          {error && <ErrorContainer>{errorMessage}</ErrorContainer>}
        </form>

      </StyledContainer>

      {/* Contact details */}
      <ContactDetails background={contactDetailsBackground}>
        <StyledContainer>
          <ContactDetailsRow className="contact-details-content">
            <ContactDetailsCol><RichText text={contactDetailsCol1} /></ContactDetailsCol>
            <ContactDetailsCol><RichText text={contactDetailsCol2} /></ContactDetailsCol>
          </ContactDetailsRow>
        </StyledContainer>
      </ContactDetails>
    </div>
  );
}

ContactForm.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    subjects: PropTypes.arrayOf(PropTypes.string),
    submitButton: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    contactDetailsBackground: PropTypes.string,
    contactDetailsCol1: PropTypes.string,
    contactDetailsCol2: PropTypes.string
  })
};
